<template>
  <!-- 公司动态 -->
  <div class="company">
    <my-notice :id="2" />
  </div>
</template>

<script>
export default {
  name: "company",
  components: {},
};
</script>
